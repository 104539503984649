/* ======= BOOTSTRAP VARIABLES, IMPORT & FONTS ======= */

$primary: #27A9E1;
$grey: #E6E9E9;
$green: #25D366;
$black: #272727;
$theme-colors: ();
$theme-colors: map-merge(
	(
		"primary": $primary,
		"grey": $grey,
		"green": $green,
		"black": $black
		),
	$theme-colors
	);

$body-color: #818A91;

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
	(
		0: 0,
		1: ($spacer * .25),
		2: ($spacer * .5),
		3: $spacer,
		4: ($spacer * 1.5),
		5: ($spacer * 3),
		6: ($spacer * 4),
		7: ($spacer * 5),
		8: ($spacer * 6),
		9: ($spacer * 7)
		),
	$spacers
	);

$font-family-sans-serif: 'Ubuntu', sans-serif;
$headings-font-family:        'Lato', sans-serif;
$headings-line-height: 1.1;
$display1-weight: 700;
$display2-weight: 600;

$nav-pills-border-radius: 0;


$btn-font-weight: 400;


$input-btn-padding-x: 1.375rem;
$input-btn-padding-x-sm: 1rem;
$input-btn-padding-x-lg: 1.25rem;
$btn-border-radius: 2rem !default;
$btn-border-radius-lg: 2rem;
$btn-border-radius-sm: 2rem;

$form-feedback-invalid-color: #FDA595;


@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i');

@import '../../../node_modules/bootstrap/scss/functions';
@import 'bootstrap-variables';
@import 'font-awesome-variables';

@import '../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/brands';



/* ======= LAYOUT ======= */

.container-fluid {
	max-width: 100rem;
	&.nomw {
		max-width: 100%;
	}
}

.bg-cover {
	background-repeat: no-repeat;
	background-position: 50% 25%;
	background-size: cover;
}

.fullscreen {
	position: relative;
	overflow: hidden;
	padding-top: 3rem;
	padding-bottom: 3rem;
	@include media-breakpoint-up(lg) {
		padding-top: 0;
		padding-bottom: 0;
		min-width: 100%;
		width: 100vw;
		min-height: 100%;
		height: 100vh;
		max-width: 100%;
	}
	&#intro {
		&:before {
			@extend .bg-cover;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;		
			transform-origin: center;
			transform: scale(1.0);
			z-index: -1;
			pointer-events: none;
			background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/intro.jpg');
		}
		&.is-visible:before {
			animation: zoom 15s linear 0s 1 forwards;
		}
	}
	&#intro-team {
		&:before {
			@extend .bg-cover;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;		
			transform-origin: center;
			transform: scale(1.0);
			z-index: -1;
			pointer-events: none;
			background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/intro-team.jpg');
		}
		&.is-visible:before {
			animation: zoom 15s linear 0s 1 forwards;
		}
	}

	&#intro-corsica {
		&:before {
			@extend .bg-cover;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;		
			transform-origin: center;
			transform: scale(1.0);
			z-index: -1;
			pointer-events: none;
			background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/intro-corsica.jpg');
		}
		&.is-visible:before {
			animation: zoom 15s linear 0s 1 forwards;
		}
	}


	&#intro-madesimo {
		&:before {
			@extend .bg-cover;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;		
			transform-origin: center;
			transform: scale(1.0);
			z-index: -1;
			pointer-events: none;
			background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/intro-inverno-madesimo.jpg');
		}
		&.is-visible:before {
			animation: zoom 15s linear 0s 1 forwards;
		}
	}


	&#intro-engadina {
		&:before {
			@extend .bg-cover;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;		
			transform-origin: center;
			transform: scale(1.0);
			z-index: -1;
			pointer-events: none;
			background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/intro-inverno-engadina.jpg');
		}
		&.is-visible:before {
			animation: zoom 15s linear 0s 1 forwards;
		}
	}


	&#intro-sardegna {
		&:before {
			@extend .bg-cover;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;		
			transform-origin: center;
			transform: scale(1.0);
			z-index: -1;
			pointer-events: none;
			background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/intro-selvaggio-blu-sardegna-trekking.jpg');
		}
		&.is-visible:before {
			animation: zoom 15s linear 0s 1 forwards;
		}
	}
	&#intro-speleo {
		&:before {
			@extend .bg-cover;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;		
			transform-origin: center;
			transform: scale(1.0);
			z-index: -1;
			pointer-events: none;
			background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/intro-speleo.jpg');
		}
		&.is-visible:before {
			animation: zoom 15s linear 0s 1 forwards;
		}
	}


	&#intro-ferrate {
		&:before {
			@extend .bg-cover;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;		
			transform-origin: center;
			transform: scale(1.0);
			z-index: -1;
			pointer-events: none;
			background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/intro-ferrate.jpg');
		}
		&.is-visible:before {
			animation: zoom 15s linear 0s 1 forwards;
		}
	}

	&#intro-canyon-vitelli {
		&:before {
			@extend .bg-cover;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;		
			transform-origin: center;
			transform: scale(1.0);
			z-index: -1;
			pointer-events: none;
			background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/intro-canyon-valle-vitelli.jpg');
		}
		&.is-visible:before {
			animation: zoom 15s linear 0s 1 forwards;
		}
	}


	&#intro-canyon-canoa {
		&:before {
			@extend .bg-cover;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;		
			transform-origin: center;
			transform: scale(1.0);
			z-index: -1;
			pointer-events: none;
			background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/intro-canoa-canyoning.jpg');
		}
		&.is-visible:before {
			animation: zoom 15s linear 0s 1 forwards;
		}
	}

	&#intro-ferrate-altre {
		&:before {
			@extend .bg-cover;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;		
			transform-origin: center;
			transform: scale(1.0);
			z-index: -1;
			pointer-events: none;
			background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/intro-ferrate-altre.jpg');
		}
		&.is-visible:before {
			animation: zoom 15s linear 0s 1 forwards;
		}
	}
}

@keyframes zoom {
	0% {
		transform: scale(1.0);
	}
	100% {
		transform: scale(1.2);
	}
}

#content {
	&-top-img {
		@extend .bg-cover;
		background-image: url('../assets/img/content-top.jpg');
		min-height: 15rem;
	}


	&-top-img-ferrate {
		@extend .bg-cover;
		background-image: url('../assets/img/content-top-ferrate.jpg');
		min-height: 15rem;
	}

	&-top-img-canyoning {
		@extend .bg-cover;
		background-image: url('../assets/img/content-top-canyoning.jpg');
		min-height: 15rem;
	}

	&-top-img-corsica {
		@extend .bg-cover;
		background-image: url('../assets/img/content-top-corsica.jpg');
		min-height: 15rem;
	}


	&-top-img-intro {
		@extend .bg-cover;
		background-image: url('../assets/img/content-top-intro.jpg');
		min-height: 15rem;
	}
	&-bottom-img {
		@extend .bg-cover;
		background-image: url('../assets/img/content-bottom.jpg');
		min-height: 15rem;
	}

	&-bottom-blu {
		@extend .bg-cover;
		background-image: url('../assets/img/content-bottom-blu.jpg');
		min-height: 15rem;
	}

	&-top-blu {
		@extend .bg-cover;
		background-image: url('../assets/img/content-top-blu.jpg');
		min-height: 15rem;
	}



	&-bottom-img-app {
		@extend .bg-cover;
		background-image: url('../assets/img/content-bottom-app.jpg');
		min-height: 15rem;
	}
	&-bottom-img-link-canyoning {
		@extend .bg-cover;
		background-image: url('../assets/img/content-bottom-link-canyoning.jpg');
		min-height: 15rem;
	}

	&-bottom-img-ferrate-corsica {
		@extend .bg-cover;
		background-image: url('../assets/img/content-bottom-ferrate-corsica.jpg');
		min-height: 15rem;
	}

	&-bottom-img-app-ferrate {
		@extend .bg-cover;
		background-image: url('../assets/img/content-bottom-ferrate.jpg');
		min-height: 15rem;
	}

	&-bottom-img-canyoning {
		@extend .bg-cover;
		background-image: url('../assets/img/content-bottom-canyoning.jpg');
		min-height: 15rem;
	}


	&-bottom-img-k {
		@extend .bg-cover;
		background-image: url('../assets/img/content-bottom-chi-siamo.jpg');
		min-height: 15rem;
	}
}

#details-img {
	@extend .bg-cover;
	background-image:  linear-gradient(rgba(36,34,34,0.72), rgba(36,34,34,0.72)), url('../assets/img/details.jpg');
}

#call {
	@extend .bg-cover;
	background-image:  linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/download.jpg');
	&-bottom {
		@extend .bg-cover;
		background-image:  linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/call-bottom.jpg');
	}
	&-bottom-corsica {
		@extend .bg-cover;
		background-image:  linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/call-bottom-corsica.jpg');
	}

	&-bottom-engadin {
		@extend .bg-cover;
		background-image:  linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/call-inverno-engadin.jpg');
	}

	&-bottom-madesimo {
		@extend .bg-cover;
		background-image:  linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/call-inverno-madesimo.jpg');
	}

	&-bottom-ferrate {
		@extend .bg-cover;
		background-image:  linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/call-bottom-ferrate.jpg');
	}

	&-bottom-hp {
		@extend .bg-cover;
		background-image:  linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../assets/img/call-bottom-hp.jpg');
	}
}


/* ======= TYPO ======= */

@include media-breakpoint-down(lg) {
	html {
		font-size: 0.875rem;
	}
}

h1,
.h1 {
	font-size: 1.6rem;
	color: $primary;
	font-weight: 700;
	@include media-breakpoint-up(lg) {
		font-size: 2.2rem;
	}
}

h2,
.h2 {
	font-size: 1.4rem;
	font-weight: 300;
	line-height: 2.3rem !important;
	color: $black;
	@include media-breakpoint-up(lg) {
		font-size: 1.8rem;
	}
}

h3,
.h3 {
	color: $black;
	font-weight: 400;
	font-size: 1.5rem;
	@include media-breakpoint-up(lg) {
		font-size: 1.25rem;
	}
}

h5,
.h5 {
	color: #373A3C;
	font-weight: 600;
}

.display {
	&-1 {
		font-size: 2.3rem;
		@include media-breakpoint-up(lg) {
			font-size: 3.5rem;
		}
	}
	&-2 {
		font-size: 1.55rem;
		line-height: 2.4rem !important;
		@include media-breakpoint-up(lg) {
			font-size: 2.575rem;
			line-height: 3rem !important;
		}
	}
	&-3 {
		font-size: 1.5rem;
			line-height: 2.3rem !important;
		@include media-breakpoint-up(lg) {
			font-size: 3rem;
				line-height: 3.2rem !important;
		}
	}
}


.big {
	font-size: 1.15rem;
	line-height: 1.4;
	@include media-breakpoint-up(xl) {
		font-size: 1.25rem;
	}
}

ul.list-big {
	list-style: disc;
	padding: 0;
	li {
		padding-left: 0.9rem;
		margin-bottom: 0.5rem;
		position: relative;
	}
}

.teamimg {max-width: 80%;}

.content-team, h3 {font-weight: 700 !important;
font-size: 1.8rem;}

.navbar-light .navbar-nav .nav-link {
	color: $primary !important;
	font-size: 1.1rem;
	font-weight: 500;
}
.navbar-nav .nav-link {
	padding-right: .7rem !important;
	padding-left: .7rem !important;
}
.nav-link {
	display: block;
	padding: .5rem 1rem;
}

dl {
	margin: 20px 0 20px 0;

}

dt {
	margin-top: 15px;
	color: $primary;
}


/* ======= FOOTER ======= */

footer {
	border-top: 1px solid #707070;
}


/* ======= ELEMENTS ======= */

.logo {
	width: 45vw;
	@include media-breakpoint-up(lg) {
		width: 20vw;
	}
}

#promo {
	img {
		transition: transform .5s linear, box-shadow .5s linear !important;
		&:hover {
			transform: scale(0.99) !important;
			box-shadow: 0 0.15rem 0.25rem rgba(34,34,34,.15)!important;
		}
	}
}



/* ======= BUTTONS ======= */

.btn {
	text-transform: uppercase;
	font-size: 1.1rem;
	@include media-breakpoint-up(xl) {
		font-size: 1.325rem;
	}
	&-primary {
		@include button-variant($primary, $primary, $dark, $dark);
		color: #FFF;
	}
	&-secondary {
		@include button-variant(#FFFFFF, $primary, $primary);
		color: $primary;
		&:hover,
		&.hover,
		&:focus,
		&.focus,
		&:active,
		&.active,
		&:not(:disabled):not(.disabled):active {
			color: $light;
			border-color: $dark;
			background-color: $dark;
		}
	}
}

a .fa-angle-down:hover {
	color: $white !important;
}

/* ======= UTILS ======= */


/* ======= BACK TO TOP ======= */

#top {
	position: fixed;
	width: 4rem;
	height: 4rem;
	background: #222222;
	bottom: 3rem;
	right: 3rem;
	cursor: pointer;
	border: 4px solid $primary;
	#top-arrow {
		-o-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		border-top: 4px solid $primary;
		border-left: 4px solid $primary;
		border-bottom: none;
		border-right: none;
		width: 1rem;
		height: 1rem;
		margin: 1.55rem auto;
	}
}